import {Component, OnInit} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {GrowlService} from "./services/growl.service";
import {AppUtilsService} from "./services/app-utils.service";
import {SpinnerService} from "./services/spinner.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'Materialpro Angular Admin Tempplate';

  constructor(private snackBar: MatSnackBar,
              private growlService: GrowlService,
              private appUtilsService: AppUtilsService) {
    this.appUtilsService.applyClientTheme();
  }

  ngOnInit(): void {
    this.growlService.successGrowl.subscribe((data) => {
      this.snackBar.open(data.message, data.action, Object.assign({
        duration: 5000,
        verticalPosition: 'top',
        horizontalPosition: "right",
        panelClass:['success-bar']
      }, data.config))
    });

    this.growlService.errorGrowl.subscribe((data) => {
      this.snackBar.open(data.message, data.action, Object.assign({
        duration: 5000,
        verticalPosition: 'top',
        horizontalPosition: "right",
        panelClass:['error-bar']
      }, data.config))
    });

    this.growlService.warnGrowl.subscribe((data) => {
      this.snackBar.open(data.message, data.action, Object.assign({
        duration: 5000,
        verticalPosition: 'top',
        horizontalPosition: "right",
        panelClass:['warn-bar']
      }, data.config))
    });

    this.growlService.infoGrowl.subscribe((data) => {
      this.snackBar.open(data.message, data.action, Object.assign({
        duration: 5000,
        verticalPosition: 'top',
        horizontalPosition: "right",
        panelClass:['info-bar']
      }, data.config))
    });
  }


}
