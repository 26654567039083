import {Injectable} from '@angular/core';
import {BaseTableService} from "../../common/base-table/base-table.service";
import {Observable, of} from 'rxjs';
import {IPage} from 'src/app/common/page.interface';
import {ApiGatewayService} from "../../services/api-gateway.service";
import {HttpParams} from "@angular/common/http";
import {IResourceDownload} from "../../common/resource-download.interface";

@Injectable({
    providedIn: 'root'
})
export class BusinessUnitsService extends BaseTableService {
    override downloadResource(resourceName: string): Observable<IResourceDownload> {
        throw new Error('Method not implemented.');
    }
    override _lastFilter: any;
    override _applyLastFilter: boolean;
    override name = "Unidades de Negocio";

    override getAllPageable(pageNumber: number, pageSize: number, filters?: any): Observable<IPage<any>> {
        throw new Error('Method not implemented.');
    }

    override getAllPageableById(pageNumber: number, pageSize: number, id: string, filters?: any): Observable<IPage<any>> {
        throw new Error('Method not implemented.');
    }

    override getAll(filters?: any): Observable<any> {
        let params = new HttpParams();
        if (filters) {
            for (let filterName of Object.keys(filters)) {
                if (filters[filterName] && filterName !== 'paymentDateFrom' && filterName !== 'paymentDateTo') {
                    params = params.append(filterName, filters[filterName]);
                }
            }

            if (filters.paymentDateFrom && filters.paymentDateTo) {
                params = params
                    .append('paymentDateFrom', filters.paymentDateFrom.toString())
                    .append('paymentDateTo', filters.paymentDateTo.toString());
            }
        }
        return this.api.get<any>('/business-units', params);
    }

    override getDetail(id: string): Observable<any> {
        throw new Error('Method not implemented.');
    }

    override getReport(filters: any): Observable<any> {
        throw new Error('Method not implemented.');
    }

    constructor(private api: ApiGatewayService) {
        super();
    }

    register(request: any) {
        return this.api.post('/business-units', request);
    }
}
