import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  private _statusSpinner: Subject<boolean> = new Subject<boolean>();

  constructor() { }

  get statusSpinner(): Subject<boolean> {
    return this._statusSpinner;
  }

  show(status: boolean = true) {
    this._statusSpinner.next(status);
  }
}
