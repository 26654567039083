import {
    Component,
    Output,
    EventEmitter,
    Input,
    ViewEncapsulation,
} from '@angular/core';
import {CoreService} from 'src/app/services/core.service';
import {MatDialog} from '@angular/material/dialog';
import {navItems} from '../../../../sidebar-data';
import {TranslateService} from '@ngx-translate/core';
import {Router, RouterModule} from '@angular/router';
import {MaterialModule} from 'src/app/material.module';
import {TablerIconsModule} from 'angular-tabler-icons';
import {FormsModule} from '@angular/forms';
import {NgScrollbarModule} from 'ngx-scrollbar';
import {BrandingComponent,} from '../sidebar/branding.component';
import {NgFor, NgForOf, NgIf} from '@angular/common';
import {CognitoService} from "../../../../services/cognito/cognito.service";
import {LaddaModule} from "angular2-ladda";
import {AppUpdatePasswordModalComponent} from "../../../../modals/update-password/update-password-modal.component";
import {
    UpdatePasswordModalService
} from "../../../../modals/update-password/update-password-modal.service";
import {AppUtilsService} from "../../../../services/app-utils.service";
import {PipeModule} from "../../../../pipe/pipe.module";
import {UsersService} from "../../../../pages/users/users.service";
import {GrowlService} from "../../../../services/growl.service";
import {ErrorService} from "../../../../services/error.service";
import {SpinnerService} from "../../../../services/spinner.service";

@Component({
    selector: 'search-dialog',
    standalone: true,
    imports: [RouterModule, MaterialModule, TablerIconsModule, FormsModule, NgForOf, PipeModule],
    templateUrl: 'search-dialog.component.html'
})
export class AppSearchDialogComponent {
    searchText: string = '';

    navItems = navItems;

    navItemsData = navItems.filter((navitem) => navitem.displayName);

    // filtered = this.navItemsData.find((obj) => {
    //   return obj.displayName == this.searchinput;
    // });
}


@Component({
    selector: 'app-header',
    standalone: true,
    imports: [RouterModule, NgScrollbarModule, TablerIconsModule, MaterialModule, BrandingComponent, NgFor, NgIf, AppSearchDialogComponent, LaddaModule, PipeModule],
    templateUrl: './header.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent {
    @Input() showToggle = true;
    @Input() toggleChecked = false;
    @Output() toggleMobileNav = new EventEmitter<void>();
    @Output() toggleMobileFilterNav = new EventEmitter<void>();
    @Output() toggleCollapsed = new EventEmitter<void>();

    showFiller = false;
    loading: boolean;
    user: any;
    name: string;

    constructor(
        public dialog: MatDialog,
        private spinnerService: SpinnerService,
        private cognitoService: CognitoService,
        private usersService: UsersService,
        private growlService: GrowlService,
        private errorService: ErrorService,
        private router: Router,
        private updatePasswordModalService: UpdatePasswordModalService
    ) {
        cognitoService.getUser().then((user) => {
            this.user = user;
            this.name = user.attributes.name;

            if (user.attributes.middleName) {
                this.name += ` ${user.attributes.middleName}`
            }

            if (user.attributes.lastName) {
                this.name += ` ${user.attributes.lastName}`
            }
        });
    }

    async SignOut() {
        this.loading = true;
        this.spinnerService.show();
        this.usersService.signOutSession().subscribe(() => {
            this.cognitoService.signOutSubject();
            localStorage.clear();
            this.spinnerService.show(false);
            this.router.navigate(['/login']);
        }, (err) => {
            this.spinnerService.show(false);
            console.error('Error calling tcpay API.', err);
            this.growlService.show('error', this.errorService.handleError(err));
            this.loading = false;
        });
    }

    changePassword() {
        const dialogRef = this.dialog.open(AppUpdatePasswordModalComponent,
            {width: '30%', minWidth: '400px'});
        this.updatePasswordModalService.closeModal.subscribe((close) => {
            if (close) {
                dialogRef.close();
            }
        });
    }
}

