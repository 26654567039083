import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {environment} from "../../../environments/environment";
import {Amplify, Auth} from "aws-amplify";
import * as QRCode from 'qrcode';

@Injectable({
    providedIn: 'root'
})
export class CognitoService {

    private readonly TCP_APP = 'TCPagos MX';

    private authenticationSubject: BehaviorSubject<any>;
    public userSubject: BehaviorSubject<any>;
    public emailSubject: BehaviorSubject<any>;

    private currentUser: any;

    constructor() {
        Amplify.configure({
            Auth: {
                ...environment.cognito,
                mfaConfiguration: 'OPTIONAL'
            },
        });

        this.authenticationSubject = new BehaviorSubject<boolean>(false);
        this.userSubject = new BehaviorSubject<any>(null);
        this.emailSubject = new BehaviorSubject<any>(null);
    }

    async signIn(username: string, password: string) {
        try {
            const user = await Auth.signIn(username, password);
            this.currentUser = user;
            this.authenticationSubject.next(true);

            // Si MFA está configurado, devuelve el tipo de desafío
            if (user.challengeName === 'MFA_SETUP') {
                return { status: 'MFA_SETUP', cognitoUser: user };  // Necesita configurar MFA
            } else if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                return { status: 'SOFTWARE_TOKEN_MFA', cognitoUser: user };  // MFA ya está configurado
            }

            return { status: 'SIGNED_IN', cognitoUser: user };  // Si no hay MFA, se ha iniciado sesión correctamente
        } catch (error) {
            console.error('Error en el inicio de sesión:', error);
            throw error;
        }
    }

    async setupMFA(isUpdate?: boolean) {
        try {
            if (isUpdate) {
                this.currentUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
            }
            await Auth.setPreferredMFA(this.currentUser, 'NOMFA');
            await Auth.setPreferredMFA(this.currentUser, 'TOTP');
            const secret = await Auth.setupTOTP(this.currentUser);
            // Generamos el código QR usando el secreto
            const otpAuthUrl = `otpauth://totp/${this.TCP_APP}?secret=${secret}`;
            return { qrCode: await QRCode.toDataURL(otpAuthUrl), secretKey: secret };
        } catch (error) {
            console.error('Error al configurar MFA:', error);
            throw error;
        }
    }

    // Verificación del código MFA
    async verifyMFA(code: string) {
        try {
            await Auth.verifyTotpToken(this.currentUser, code);
            await Auth.setPreferredMFA(this.currentUser, 'TOTP');
            console.log('MFA verificado correctamente');
        } catch (error) {
            console.error('Error de verificación de MFA:', error);
            throw error;
        }
    }

    async confirmSignIn(code: string) {
        try {
            await Auth.confirmSignIn(this.currentUser, code, 'SOFTWARE_TOKEN_MFA');
            console.log('MFA verificado correctamente');
        } catch (error) {
            console.error('Error de verificación de MFA:', error);
            throw error;
        }
    }

    public async sendCustomChallengeAnswer(cognitoUser: any, answer: string): Promise<any> {
        let user = await Auth.sendCustomChallengeAnswer(cognitoUser, answer);
        this.authenticationSubject.next(true);
        return Promise.resolve(user);
    }

    public async signOut(): Promise<any> {
        return Auth.signOut()
            .then(() => {
                this.authenticationSubject.next(false);
            });
    }

    public signOutSubject(): void {
        this.authenticationSubject.next(false);
    }

    public async isAuthenticated(): Promise<boolean> {
        if (this.authenticationSubject.value) {
            return Promise.resolve(true);
        } else {
            try {
                let user = await this.getUser();
                return !!user;
            } catch (e) {
                return false;
            }
        }
    }

    public getUser(): Promise<any> {
        return Auth.currentUserInfo();
    }

    public async getJwtToken(): Promise<any> {
        let res = await Auth.currentSession();
        let idToken = res.getIdToken();
        return idToken.getJwtToken();
    }

    public changeUserPassword(newPsw: string): Promise<any> {
        return new Promise(async (resolve, reject) => {
            Auth.completeNewPassword(this.userSubject.value, newPsw)
                .then((x) => resolve(x))
                .catch((e) => reject(e));
        });
    }

    public handleError(error: any): string {
        switch (error.code) {
            case 'NotAuthorizedException':
                return 'Usuario y/o contraseña incorrectos.'
            case 'ExpiredCodeException':
                return 'El código de verificación expiró.'
            case 'CodeMismatchException':
                return 'El código de verificación es invalido o ha expirado. Por favor intenta de nuevo.'
            case 'UsernameExistsException':
                return 'El usuario registrado ya existe.'
            case 'InvalidParameterException':
                return 'Parámetros inválidos. Verifica tu petición.'
            case 'InvalidPasswordException':
                return 'La contraseña proporcionada no cumple con los parámetros requeridos.'
            case 'UserNotFoundException':
                return 'Usuario no encontrado.'
            case 'LimitExceededException':
                return 'Excediste el número de peticiones por minuto, intenta más tarde. Si el problema persiste ponte en contacto con nosotros.'
            default:
                return 'Error inesperado, intenta más tarde. Si el problema persiste ponte en contacto con nosotros.'
        }
    }

    public async updateUserPassword(oldPassword: string, newPassword: string): Promise<"SUCCESS"> {
        let user = await Auth.currentAuthenticatedUser();
        return Auth.changePassword(user, oldPassword, newPassword);
    }

    async forgotPassword(email: string) {
        return await Auth.forgotPassword(email);
    }

    async forgotPasswordSubmit(email: string, code: string, newPassword: string) {
        return await Auth.forgotPasswordSubmit(email, code, newPassword);
    }

    async refreshSession() {
        const user = await Auth.currentUserPoolUser();
        const session: any = await Auth.currentSession();
        const refreshToken = session.refreshToken;
        const refreshRequest = new Promise((res, rej) => {
            user.refreshSession(refreshToken, (data: any) => {
                return res(data);
            });
        });
        await refreshRequest;
    }

    async disableMFA() {
        const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
        await Auth.setPreferredMFA(user, 'TOTP');
        await Auth.setPreferredMFA(user, 'NOMFA');
    }
}
