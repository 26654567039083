<div *ngIf="loading" class="w-100 h-100" style="position: absolute; display: flex; justify-content: center; align-items: center; background: rgba(0, 0, 0, 0.5); z-index: 100;">
    <mat-progress-spinner
            mode="indeterminate">
    </mat-progress-spinner>
</div>
<div
  autosize
  autoFocus
  [ngClass]="{
    'sidebarNav-mini': options.sidenavCollapsed && options.navPos !== 'top',
    'sidebarNav-horizontal': options.horizontal,
    cardBorder: options.cardBorder,
    orange_theme: options.activeTheme == 'orange_theme',
    blue_theme: options.activeTheme == 'blue_theme',
    aqua_theme: options.activeTheme == 'aqua_theme',
    purple_theme: options.activeTheme == 'purple_theme',
    green_theme: options.activeTheme == 'green_theme',
    cyan_theme: options.activeTheme == 'cyan_theme'
  }"
  [dir]="options.dir!"
>
  <!-- ============================================================== -->
  <!-- VerticalHeader -->
  <!-- ============================================================== -->


  <mat-sidenav-container class="mainWrapper">
    <!-- ============================================================== -->
    <!-- Vertical Sidebar -->
    <!-- ============================================================== -->

    <mat-sidenav
      #leftsidenav
      [mode]="isOver ? 'over' : 'side'"
      [opened]="options.navPos === 'side' && options.sidenavOpened && !isOver"
      *ngIf="!options.horizontal"
      (openedChange)="onSidenavOpenedChange($event)"
      (closedStart)="onSidenavClosedStart()"
      class="sidebarNav"
    >
<!--      <app-sidebar> </app-sidebar>-->
        <app-branding [collapsed]="this.options.sidenavCollapsed"></app-branding>
      <ng-scrollbar
        class="position-relative mini-scroll"
        style="height: calc(100vh - 230px)"
      >
        <mat-nav-list class="sidebar-list">
          <app-nav-item
            *ngFor="let item of navItems"
            [item]="item"
            (notify)="sidenav.toggle()"
          >
          </app-nav-item>
        </mat-nav-list>
      </ng-scrollbar>
    </mat-sidenav>
    <!-- ============================================================== -->
    <!-- Main Content -->
    <!-- ============================================================== -->
    <mat-sidenav-content class="contentWrapper">
      <ng-container>
        <app-header
          [showToggle]="!isOver"
          (toggleCollapsed)="toggleCollapsed()"
          (toggleMobileNav)="sidenav.toggle()"
        ></app-header>
      </ng-container>
      <main
        class="pageWrapper"
        [ngClass]="{ maxWidth: options.boxed }"
      >
        <app-breadcrumb></app-breadcrumb>
        <!-- ============================================================== -->
        <!-- Outlet -->
        <!-- ============================================================== -->
        <router-outlet></router-outlet>
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
