import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {ApiGatewayService} from "../../services/api-gateway.service";
import {IPage} from "../../common/page.interface";
import {IPayment} from "./payment.interface";
import {BaseTableService} from "../../common/base-table/base-table.service";
import {Observable} from "rxjs";
import {IResourceDownload} from "../../common/resource-download.interface";

@Injectable({
    providedIn: 'root'
})
export class PaymentsService extends BaseTableService {
    name = 'Pagos';
    _applyLastFilter: boolean;
    _lastFilter: any;

    constructor(private api: ApiGatewayService) {
        super();
    }

    getAllPageable(pageNumber: number, pageSize: number, filters?: any) {
        let params = new HttpParams()
            .append('number', pageNumber)
            .append('size', pageSize);

        if (filters) {
            for (let filterName of Object.keys(filters)) {
                if (filters[filterName] && filterName !== 'paymentDateFrom' && filterName !== 'paymentDateTo') {
                    params = params.append(filterName, filters[filterName]);
                }
            }

            if (filters.paymentDateFrom && filters.paymentDateTo) {
                params = params
                    .append('paymentDateFrom', filters.paymentDateFrom.toString())
                    .append('paymentDateTo', filters.paymentDateTo.toString());
            }
        }

        return this.api.get<IPage<IPayment[]>>('/payments', params);
    }

    getDetail(paymentId: string) {
        return this.api.get<any>(`/payments/${paymentId}`);
    }

    async getResume() {
        return this.api.get<any>('/payments/resume');
    }

    getAll(): Observable<any> {
        return new Observable<any>();
    }

    getAllPageableById(pageNumber: number, pageSize: number, id: string, filters?: any): Observable<IPage<any>> {
        return new Observable<IPage<any>>();
    }

    getReport(filters: any): Observable<any> {
        let params = new HttpParams();
        if (filters) {
            for (let filterName of Object.keys(filters)) {
                if (filters[filterName] && filterName !== 'paymentDateFrom' && filterName !== 'paymentDateTo') {
                    params = params.append(filterName, filters[filterName]);
                }
            }

            if (filters.paymentDateFrom && filters.paymentDateTo) {
                params = params
                    .append('paymentDateFrom', filters.paymentDateFrom.toString())
                    .append('paymentDateTo', filters.paymentDateTo.toString());
            }
        }
        return this.api.get('/payments/download', params, undefined, 'blob');
    }

    getStoreCatalog() {
        return this.api.get<{ value: string, name: string }[]>('/admin/store/catalog');
    }

    override downloadResource(resourceName: string): Observable<IResourceDownload> {
        throw new Error('Method not implemented.');
    }
}
